import * as React from "react"
import { ContentWrapper } from "./ContentWrapper"
import { Flex, Box, Text, Link } from "rebass/styled-components"
import { theme } from "../theme"
import { Logo } from "./common/LogoWhite"
import { routes } from "../page-data/routes"
import { menuItems } from "../page-data/menu"
import { Link as RouterLink } from "gatsby"
import { LogoUniversity } from "./common/LogoUniversity"

const findNameByUrl = (url: string) => menuItems.find(i => i.url === url)?.name

export const Footer = () => (
  <Flex bg={theme.colors.bgDark} as="footer" pb={3}>
    <ContentWrapper maxWidth="1290px">
      <Flex flexWrap="wrap" mx={-4} px={4}>
        <Box width={[1, 1, 1 / 3]}>
          <Link href="https://www.jhu.edu/">
            <LogoUniversity style={{ position: "relative", left: -38 }} />
            {/*<Logo style={{ position: "relative", left: -38, top: 30 }} />*/}
          </Link>
        </Box>

        <Flex
          px={[0, 0, 0, 6]}
          justifyContent={"space-around"}
          width={[1, 1, 2 / 3]}
          pt={[0, 0, 4, 4, 5, 6]}
        >
          <Box width={[1, 1, 1 / 2]}>
            <FooterLink url={routes.home} />
            <FooterLink url={routes.about} />
            <FooterLink
              url={routes.storymap}
              content={<span>Geography, Ticks&nbsp;and You</span>}
            />
          </Box>
          <Box width={[1, 1, 1 / 2]}>
            <FooterLink url={routes.overview} />
            <FooterLink url={routes.explorer} />
            <FooterLink url={routes.news} />
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end">
        <Link href={"https://www.jhsph.edu/resources/web-policies.html"}>
          <Text color="white">Privacy Policy</Text>
        </Link>
      </Flex>
    </ContentWrapper>
  </Flex>
)

const FooterLink = ({
  url,
  content,
}: {
  url: string
  content?: React.ReactElement
}) => (
  <Link href={url} as={RouterLink}>
    <Text color="white" fontFamily="Gentona-Book" fontSize="18px" pb={2}>
      {content ? content : findNameByUrl(url)}
    </Text>
  </Link>
)
