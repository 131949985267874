import styled from "styled-components"
import { Box } from "rebass/styled-components"

export const ContentWrapper = styled(Box)<{ maxWidth?: number | string }>`
  margin: 0 auto;
  width: calc(100vw - 48px);
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: calc(100vw - 80px);
  }
`

ContentWrapper.defaultProps = { maxWidth: "1152px" }
