export const appBasePath = "/app"

export const routes = {
  home: "/",
  dashboard: `${appBasePath}`,
  overview: `/overview`,
  explorer: `/explorer`,
  storymap: "/geography-ticks-and-you/",
  news: "/news",
  about: "/about",
  shiny: {
    overview: "https://cwychgr2.shinyapps.io/jhu-lyme/?p=overview",
    explorer: "https://cwychgr2.shinyapps.io/jhu-lyme/?p=explorer",
  },
}
