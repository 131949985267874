export const theme = {
  extendedContentWidth: 1290,
  contentWidth: 1152,

  breakpoints: ["480px", "768px", "1024px", "1200px"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    body: "#1B4942",
    red: "#e96868",

    heading: "#1B4942",
    headingBgDark: "#fff",
    headingAccent: "#4AD880",
    link: "#339257",
    text: "#1B4942",
    textWhite: "#fff",
    textAccent: "#0072CE",
    bgUltraLight: "#E8F2EE",
    bgSuperLight: "#F8FBF6",
    bgLight: "#E8F2EE",
    bg: "white",
    bgDark: "#143630",
    dark: "#222222",
    blues_UNUSED: {
      heading: "#002D72",
      text: "rgba(0,45,114, 0.78)",
      textAccent: "#0072CE",
      bgUltraLight: "#F4FAFF",
      bgSuperLight: "#E8F4FF",
      bgLight: "#DFF0FF",
      bg: "#D7ECFF",
    },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "Gentona-Light, system-ui, sans-serif",
    heading: "Arnhem",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {},
  text: {
    color: "#1B4942",
  },
  link: {
    textDecoration: "none",
  },
  heading: {
    fontFamily: "Gentona-Medium",
  },
  buttons: {
    primary: {
      color: "white",
      bg: "#222222",
      borderRadius: "8px",
      ":hover": { cursor: "pointer" },
    },
    outline: {
      color: "white",
      bg: "transparent",
      border: "2px solid white",
      borderRadius: "8px",
      fontFamily: "Gentona-Light",
      ":hover": { cursor: "pointer" },
    },
    outlineDark: {
      color: "#222222",
      bg: "transparent",
      border: "2px solid #222222",
      borderRadius: "8px",
      fontFamily: "Gentona-Light",
      ":hover": { cursor: "pointer" },
    },
    underline: {
      color: "text",
      bg: "transparent",
      borderRadius: "0px",
      fontFamily: "Gentona-Book",
      fontSize: "18px",
      paddingLeft: 0,
      paddingRight: 0,
      borderBottom: "2px solid #000000",
      ":hover": { cursor: "pointer" },
    },
  },
}
