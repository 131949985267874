import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import { menuItems } from "../page-data/menu"
import { ContentWrapper } from "./ContentWrapper"
import { Flex, Box } from "rebass/styled-components"
import { HamburgerMenu } from "./HamburgerMenu"
import { LogoSvg } from "./common/LogoSvg"

const Header = () => {
  return (
    <StyledHeader p={[4]}>
      <StyledMenu mx={[4, 4, 4, 4, 0]} maxWidth={"1290px"}>
        <Link to="https://www.jhsph.edu/" style={{ position: "absolute" }}>
          <Box maxWidth={280}>
            <LogoSvg
              style={{
                width: "85%",
                position: "relative",
                top: 5,
              }}
            />
          </Box>
        </Link>

        <DesktopMenu>
          {menuItems.map(menuItem => (
            <Link to={menuItem.url} key={menuItem.url}>
              {menuItem.name}
            </Link>
          ))}
        </DesktopMenu>

        <HamburgerMenu />
      </StyledMenu>
    </StyledHeader>
  )
}

const StyledHeader = styled(Flex)`
  ${({ theme }) => `
    background: #1B4942;
    color: ${theme.colors.textWhite};
    font-family: ${theme.fonts.body};
  `};
  max-width: 100vw;
  height: 110px;
  display: flex;
`

const StyledMenu = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`

const DesktopMenu = styled.div`
  * {
    font-size: 1em;
  }

  a {
    font-family: Gentona-Book;
    text-decoration: none;
    font-size: 18px;
    line-height: 19px;
    text-align: right;

    color: ${({ theme }) => theme.fonts.body};
  }

  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    > * {
      margin-left: 32px;
      color: ${({ theme }) => theme.colors.textWhite};
    }
  }

  @media screen and (min-width: 1200px) {
    > * {
      margin-left: 64px;
    }
  }
`

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
