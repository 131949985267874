/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import * as React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "../header"
import "./layout.css"
import "./fonts.css"
import { Footer } from "../Footer"
import { ThemeProvider } from "styled-components"
import { theme } from "../../theme"
import { Box, Flex } from "rebass/styled-components"
import { initializeIcons } from "@uifabric/icons"
initializeIcons()
const Layout = (props: LayoutProps) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <ThemeProvider theme={theme}>
          <Flex
            flexDirection="column"
            minHeight="100vh"
            justifyContent="space-between"
          >
            <Header siteTitle={data.site.siteMetadata.title} />
            <Box bg={theme.colors.bg} as={"main"}>
              {props.children}
            </Box>
            <Footer />
          </Flex>
        </ThemeProvider>
      )
    }}
  />
)

interface LayoutProps {
  children?: React.ReactNode
}

export default Layout
