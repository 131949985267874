import * as React from "react"

export const LogoSvg = (props: { style?: React.CSSProperties }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 73.2" {...props}>
      <g fill="#fff">
        <path d="m26.88 14.61c1.69-3.39.69-8.35-2.67-10.27 1.63 2.63-1.13 7.39-2.75 6.83 1.26-1.81.95-3.32.47-4.07a7.41 7.41 0 0 1 -1.45 2.72c-2.39 3.06-2.31 5.54.87 6.64 3.75 1.3 3.11 3.9 2.75 4.81 5.79-2.73 8.62-8 5.68-13 .69 2.72-1.39 6.82-2.9 6.34" />
        <path d="m47.77 2.47h-.05l-.24-.06a108.65 108.65 0 0 0 -45.28 0h-.07a2.79 2.79 0 0 0 -2.13 2.71v4c0 29.11 18 40.88 23.51 43.88a2.92 2.92 0 0 0 1.35.35 2.79 2.79 0 0 0 1.35-.36c5.5-2.99 23.49-14.76 23.49-43.84v-4a2.78 2.78 0 0 0 -1.93-2.65m-39.77 32.67c-.23-.41-.46-.83-.69-1.27a22.77 22.77 0 0 1 8-6.29 22.23 22.23 0 0 0 -5.76 7.56zm7.51 9.5h-.31a44.47 44.47 0 0 1 -5.34-6.48c.19-.52.38-1 .6-1.53h6a54.11 54.11 0 0 0 -1 8m-4.37-9.5c2.29-4.47 5.75-7.76 9.78-9.08-1.7 1.94-3.11 5.11-4.08 9.08zm13 16.25a38.32 38.32 0 0 1 -7.2-5.11.86.86 0 0 0 0-.16h7.19zm0-6.75h-7.09a51.68 51.68 0 0 1 1-8h6.14zm0-9.5h-5.77c1.38-5.48 3.58-9 5.79-9.63zm1.48 16.26v-5.27h7.2v.16a38.27 38.27 0 0 1 -7.2 5.12m6.14-14.77a51.61 51.61 0 0 1 1 8h-7.12v-8zm-6.14-1.49v-9.63c2.21.65 4.41 4.15 5.79 9.63zm3.22-9.08c4 1.32 7.48 4.61 9.77 9.08h-5.67c-1-4-2.38-7.14-4.08-9.08m5.71 18.58h-.31a54.16 54.16 0 0 0 -1-8h6c.21.49.4 1 .59 1.53a43.83 43.83 0 0 1 -5.34 6.48m7.21-9.5h-1.46a22.21 22.21 0 0 0 -5.75-7.56 22.74 22.74 0 0 1 7.95 6.29c-.22.44-.45.86-.68 1.27m6.35-26a53.29 53.29 0 0 1 -4.94 23.3 24 24 0 0 0 -18.29-8.51 23.92 23.92 0 0 0 -18.28 8.48 53.29 53.29 0 0 1 -4.94-23.3v-4a1.17 1.17 0 0 1 .88-1.15 106.54 106.54 0 0 1 44.6 0 1.17 1.17 0 0 1 .93 1.14z" />
        <path d="m64.1 11.23c0-3.91-.35-4.33-2.43-4.5l-.87-.07c-.22-.14-.14-.78.07-.88 2 .07 3.23.1 4.7.1s2.6 0 4-.1c.21.1.28.74.07.88l-.53.07c-2.06.27-2.11.77-2.11 4.5v13a19.43 19.43 0 0 1 -.81 6.81 7.63 7.63 0 0 1 -6.92 5.06c-.42 0-1.51 0-1.51-.74s.52-1.61 1.26-1.61a4 4 0 0 1 1.3.21 5.51 5.51 0 0 0 1.48.24 1.51 1.51 0 0 0 1.41-.91c.8-1.65.91-6.92.91-8.82z" />
        <path d="m69.57 19.5a10.24 10.24 0 0 1 10.55-10.5c6.88 0 10.29 5 10.29 10.2a10.12 10.12 0 0 1 -10.29 10.34c-6.57 0-10.55-4.71-10.55-10m17.87.62c0-4.89-2.16-10.17-7.82-10.17-3.08 0-7.08 2.11-7.08 8.6 0 4.38 2.13 10.08 8 10.08 3.56 0 6.93-2.67 6.93-8.51" />
        <path d="m97.14 19.5c-1.42 0-1.48.06-1.48.95v4.17c0 3.12.15 3.53 1.81 3.71l.85.09c.18.12.12.65 0 .74-1.61-.06-2.61-.09-3.77-.09s-2.28.06-3.44.09a.6.6 0 0 1 -.06-.74l.51-.09c1.66-.3 1.72-.59 1.72-3.71v-10.62c0-3.12-.21-3.62-1.75-3.74l-.77-.06c-.18-.12-.12-.65.06-.74 1.45 0 2.46.09 3.73.09s2.16 0 3.44-.09c.18.09.24.62.06.74l-.57.06c-1.71.18-1.77.62-1.77 3.74v3.4c0 .92.06.95 1.48.95h8.47c1.43 0 1.49 0 1.49-.95v-3.4c0-3.12-.06-3.56-1.81-3.74l-.56-.06c-.18-.12-.12-.65 0-.74 1.37.06 2.38.09 3.59.09s2.16 0 3.5-.09c.17.09.23.62.06.74l-.63.06c-1.71.18-1.77.62-1.77 3.74v10.62c0 3.12.06 3.5 1.77 3.71l.71.09c.18.12.12.65-.06.74-1.42-.06-2.42-.09-3.58-.09s-2.28 0-3.59.09a.58.58 0 0 1 0-.74l.56-.09c1.81-.3 1.81-.59 1.81-3.71v-4.17c0-.89-.06-.95-1.49-.95z" />
        <path d="m132.26 24c0 .89 0 4.42.08 5.19-.05.17-.23.32-.56.32-.35-.5-1.21-1.54-3.79-4.47l-6.88-7.83c-.79-.91-2.81-3.34-3.43-4h-.06a7.35 7.35 0 0 0 -.15 1.92v6.46c0 1.39 0 5.25.53 6.13.18.33.77.51 1.52.57l.91.09a.54.54 0 0 1 -.06.74c-1.33-.06-2.37-.09-3.46-.09-1.25 0-2 0-3.08.09a.56.56 0 0 1 -.07-.74l.81-.09c.68-.09 1.15-.27 1.3-.59.41-1.07.38-4.69.38-6.11v-8.52a2.7 2.7 0 0 0 -.65-2.14 3.22 3.22 0 0 0 -1.84-.71l-.5-.06a.51.51 0 0 1 .06-.74c1.25.09 2.82.09 3.35.09a9.4 9.4 0 0 0 1.33-.09c.6 1.51 4.09 5.43 5.07 6.52l2.93 3.23c2 2.28 3.49 3.94 4.88 5.37h.06a3 3 0 0 0 .12-1.25v-6.29c0-1.39 0-5.25-.59-6.14-.18-.26-.65-.44-1.84-.59l-.5-.06c-.21-.17-.18-.65.06-.74 1.36.06 2.37.09 3.5.09s2 0 3.05-.09a.52.52 0 0 1 .06.74l-.42.06c-1 .15-1.54.39-1.66.62-.5 1.07-.44 4.75-.44 6.11z" />
        <path d="m140.09 29.54a9.31 9.31 0 0 1 -4.62-1.09 13.36 13.36 0 0 1 -.8-4.15c.15-.21.59-.27.71-.09.44 1.51 1.66 4.42 5.1 4.42a3.37 3.37 0 0 0 3.7-3.41 4.45 4.45 0 0 0 -2.43-4.15l-2.82-1.84a6.33 6.33 0 0 1 -3.2-5.1c0-2.81 2.2-5.09 6.05-5.09a11.94 11.94 0 0 1 2.76.38 3.84 3.84 0 0 0 1 .18 12.8 12.8 0 0 1 .54 3.61c-.12.18-.6.27-.75.09-.38-1.42-1.18-3.35-4-3.35s-3.52 1.93-3.52 3.3c0 1.71 1.42 3 2.52 3.64l2.37 1.48c1.87 1.16 3.7 2.88 3.7 5.69 0 3.26-2.46 5.48-6.31 5.48" />
        <path d="m164 17.72c-1.68 0-1.75.07-1.75 1.13v4.95c0 3.69.17 4.18 2.14 4.39l1 .11c.21.14.14.77-.07.87-1.9-.07-3.09-.1-4.46-.1-1.52 0-2.71.07-4.08.1a.67.67 0 0 1 -.07-.87l.6-.11c2-.35 2-.7 2-4.39v-12.65c0-3.69-.24-4.28-2.07-4.42l-.91-.07c-.21-.14-.14-.78.07-.88 1.72 0 2.91.1 4.43.1 1.37 0 2.56 0 4.07-.1.21.1.28.74.07.88l-.67.07c-2 .21-2.1.73-2.1 4.42v4c0 1.09.07 1.13 1.75 1.13h10c1.68 0 1.75 0 1.75-1.13v-4c0-3.69-.07-4.21-2.14-4.42l-.56-.07c-.21-.14-.14-.78.07-.88 1.62.07 2.81.1 4.25.1s2.57 0 4.15-.1c.21.1.28.74.07.88l-.74.07c-2 .21-2.11.73-2.11 4.42v12.65c0 3.69.08 4.14 2.11 4.39l.85.11c.21.14.14.77-.08.87-1.68-.07-2.88-.1-4.25-.1s-2.7 0-4.25.1a.67.67 0 0 1 -.07-.87l.67-.11c2.14-.35 2.14-.7 2.14-4.39v-4.95c0-1.06-.07-1.13-1.75-1.13z" />
        <path d="m181.4 19.5a10.24 10.24 0 0 1 10.6-10.5c6.8 0 10.24 5 10.24 10.2a10.12 10.12 0 0 1 -10.24 10.34c-6.57 0-10.55-4.71-10.55-10m17.87.62c0-4.89-2.16-10.17-7.82-10.17-3.08 0-7.08 2.11-7.08 8.6 0 4.38 2.13 10.08 8 10.08 3.56 0 6.93-2.67 6.93-8.51" />
        <path d="m207.6 24.62c0 3.12.06 3.5 2 3.71l.8.09a.59.59 0 0 1 -.06.74c-1.69-.06-2.67-.09-3.86-.09s-2.31 0-3.61.09a.57.57 0 0 1 -.06-.74l.62-.09c1.72-.24 1.78-.59 1.78-3.71v-10.93c0-2.52-.06-3-1.39-3.17l-1.07-.12a.49.49 0 0 1 .06-.74 46.19 46.19 0 0 1 5.87-.24 10.71 10.71 0 0 1 5.69 1.19 5.12 5.12 0 0 1 2.37 4.44 5 5 0 0 1 -3 4.71 9.93 9.93 0 0 1 -4.1.89c-.2-.08-.2-.53 0-.59 3.2-.59 4.35-2.4 4.35-5a4.37 4.37 0 0 0 -4.77-4.74c-1.57 0-1.6.12-1.6 1.07z" />
        <path d="m219.93 14c0-3.12-.06-3.59-1.78-3.74l-.74-.06c-.18-.12-.12-.65.06-.74 1.45.06 2.4.09 3.73.09 1.16 0 2.17 0 3.44-.09.18.09.24.62.06.74l-.56.06c-1.72.18-1.78.62-1.78 3.74v3.41c0 .56.06 1.12.39 1.12a1.64 1.64 0 0 0 .82-.33c.42-.35 1.19-1.12 1.52-1.42l3.2-3.17c.56-.53 2-2.05 2.31-2.46a.89.89 0 0 0 .21-.47c0-.12-.12-.21-.51-.3l-.8-.18a.5.5 0 0 1 .06-.74c1 .06 2.22.09 3.26.09s2.05 0 2.93-.09a.54.54 0 0 1 .06.74 7.39 7.39 0 0 0 -2.75.83 30.28 30.28 0 0 0 -4.15 3.44l-2.67 2.53c-.41.42-.68.68-.68.92s.18.44.56.92c2.82 3.22 5.1 5.89 7.41 8.32a3.45 3.45 0 0 0 2.26 1.22l.57.09a.51.51 0 0 1 -.06.74c-.77-.06-1.58-.09-3-.09-1.22 0-2.26 0-3.62.09-.21-.06-.3-.57-.12-.74l.68-.12c.42-.06.71-.15.71-.3s-.2-.41-.41-.68c-.57-.71-1.34-1.51-2.46-2.79l-2.37-2.66c-1.69-1.9-2.17-2.49-2.88-2.49-.44 0-.5.38-.5 1.42v3.83c0 3.11.06 3.52 1.72 3.7l.8.09c.18.12.12.65-.06.74-1.45-.06-2.46-.09-3.62-.09s-2.22 0-3.43.09a.56.56 0 0 1 -.06-.74l.59-.09c1.57-.24 1.63-.59 1.63-3.7z" />
        <path d="m240.27 14c0-3.15-.06-3.65-1.81-3.8l-.74-.06a.59.59 0 0 1 .06-.74c1.48.06 2.49.09 3.74.09s2.19 0 3.67-.09c.18.09.24.62.06.74l-.74.06c-1.75.15-1.81.65-1.81 3.8v10.56c0 3.15.06 3.56 1.81 3.77l.74.09c.18.12.12.65-.06.74-1.48-.06-2.49-.09-3.67-.09s-2.14 0-3.62.09a.57.57 0 0 1 -.06-.74l.62-.09c1.75-.21 1.81-.62 1.81-3.77z" />
        <path d="m265.9 24c0 .89 0 4.42.1 5.19a.55.55 0 0 1 -.57.32c-.35-.5-1.21-1.54-3.79-4.47l-6.88-7.83c-.8-.91-2.81-3.34-3.43-4h-.06a7.35 7.35 0 0 0 -.15 1.92v6.46c0 1.39 0 5.25.53 6.13.18.33.77.51 1.51.57l.92.09a.54.54 0 0 1 -.06.74c-1.33-.06-2.37-.09-3.46-.09-1.25 0-2.05 0-3.09.09a.57.57 0 0 1 -.06-.74l.8-.09c.69-.09 1.16-.27 1.31-.59.41-1.07.38-4.69.38-6.11v-8.52a2.74 2.74 0 0 0 -.65-2.14 3.22 3.22 0 0 0 -1.84-.71l-.5-.06a.51.51 0 0 1 .06-.74c1.24.09 2.82.09 3.35.09a9.4 9.4 0 0 0 1.36-.09c.6 1.51 4.09 5.43 5.07 6.52l2.87 3.23c2.05 2.28 3.5 3.94 4.89 5.37h.06a3 3 0 0 0 .12-1.25v-6.29c0-1.39 0-5.25-.59-6.14-.18-.26-.65-.44-1.84-.59l-.5-.06c-.21-.17-.18-.65.06-.74 1.36.06 2.37.09 3.49.09 1.28 0 2.05 0 3.05-.09a.52.52 0 0 1 .06.74l-.41.06c-.95.15-1.54.39-1.66.62-.5 1.07-.45 4.75-.45 6.11z" />
        <path d="m273.69 29.54a9.4 9.4 0 0 1 -4.63-1.09 14 14 0 0 1 -.8-4.15c.15-.21.6-.27.71-.09.45 1.51 1.67 4.42 5.1 4.42a3.38 3.38 0 0 0 3.71-3.41 4.45 4.45 0 0 0 -2.43-4.15l-2.82-1.84a6.33 6.33 0 0 1 -3.2-5.1c0-2.81 2.19-5.09 6.05-5.09a11.78 11.78 0 0 1 2.75.38 4 4 0 0 0 1 .18 12.83 12.83 0 0 1 .53 3.61c-.12.18-.59.27-.74.09-.38-1.42-1.18-3.35-4-3.35s-3.52 1.93-3.52 3.3c0 1.71 1.42 3 2.51 3.64l2.38 1.48c1.86 1.16 3.7 2.88 3.7 5.69 0 3.26-2.46 5.48-6.31 5.48" />
        <path d="m84.05 44.31c0-1.13-.06-1.43-.75-1.49h-.49a.26.26 0 0 1 0-.39c.83-.07 1.87-.12 3.29-.12a5.88 5.88 0 0 1 2.58.43 2 2 0 0 1 1.16 1.92 2.2 2.2 0 0 1 -1.56 2.09c0 .16.12.2.29.23a2.54 2.54 0 0 1 2 2.56c0 1.78-1.32 3-4.07 3-.45 0-1.2 0-1.84 0s-1.2 0-1.76 0c-.1 0-.12-.29 0-.38l.27-.05c.81-.13.84-.3.84-1.91zm1.26 2c0 .43 0 .46.8.43 1.56-.07 2.4-.52 2.4-2a2 2 0 0 0 -2.28-2.07 2 2 0 0 0 -.71.09c-.15 0-.21.12-.21.4zm0 3.55a3 3 0 0 0 .32 1.67 1.49 1.49 0 0 0 1.21.43c1.37 0 2.41-.61 2.41-2.15 0-1.21-.62-2.61-3-2.61-.83 0-.89.09-.89.39z" />
        <path d="m94.08 44.62c0-1.61 0-1.86-1-1.93h-.39c-.09-.06-.06-.33 0-.38h2s1.12 0 1.88 0c.09 0 .12.32 0 .38h-.37c-.9.07-.93.32-.93 1.93v5.38c0 1 0 1.36.32 1.65s.46.35 1.67.35 1.62-.06 1.87-.2a3.23 3.23 0 0 0 1-1.51c.09-.08.38 0 .38.09a12.46 12.46 0 0 1 -.64 2.14c-.78 0-2.24 0-3.81 0h-1.33c-.65 0-1.14 0-2 0-.1 0-.13-.29 0-.38l.46-.05c.89-.09.92-.3.92-1.91z" />
        <path d="m101.88 47.48a5.29 5.29 0 0 1 5.45-5.41 5 5 0 0 1 5.31 5.26 5.22 5.22 0 0 1 -5.31 5.34 5.15 5.15 0 0 1 -5.45-5.19m9.23.32c0-2.53-1.12-5.25-4-5.25-1.59 0-3.66 1.08-3.66 4.44 0 2.27 1.1 5.21 4.12 5.21 1.84 0 3.58-1.38 3.58-4.4" />
        <path d="m114.82 47.48a5.29 5.29 0 0 1 5.45-5.41 5 5 0 0 1 5.31 5.26 5.23 5.23 0 0 1 -5.31 5.34 5.15 5.15 0 0 1 -5.45-5.19m9.23.32c0-2.53-1.12-5.25-4-5.25-1.6 0-3.66 1.08-3.66 4.44 0 2.27 1.1 5.21 4.12 5.21 1.83 0 3.58-1.38 3.58-4.4" />
        <path d="m132.91 48.34c.36.72.69 1.42 1.06 2.1.42-.72.8-1.52 1.18-2.3l1.44-2.93a9.14 9.14 0 0 0 1.17-2.94 10.55 10.55 0 0 0 1.14 0h1.37a.32.32 0 0 1 0 .38h-.32c-1 .09-1.09.38-1.07 1.45 0 1.62 0 4.46.17 6.66 0 .77 0 1.18.75 1.24l.49.05a.28.28 0 0 1 0 .38c-.63 0-1.26 0-1.81 0s-1.23 0-1.85 0a.27.27 0 0 1 0-.38l.44-.05c.75-.07.75-.32.75-1.3l-.05-6.39c-.11.17-.7 1.4-1 2l-1.41 2.69c-.68 1.35-1.37 2.79-1.65 3.49a.16.16 0 0 1 -.15.06.18.18 0 0 1 -.14-.06 32.25 32.25 0 0 0 -1.35-3.06l-1.38-3c-.32-.7-.64-1.5-1-2.21-.06 1-.11 1.84-.15 2.73s-.11 2.3-.11 3.6c0 1.13.09 1.38.69 1.42l.58.05c.11.09.09.34 0 .38-.52 0-1.12 0-1.7 0s-1 0-1.56 0a.29.29 0 0 1 0-.38l.34-.05c.64-.09.86-.18.95-1.25s.17-1.85.26-3.37c.08-1.27.14-2.57.17-3.42s-.09-1.19-1-1.31h-.24a.28.28 0 0 1 .06-.38h1.21a12.13 12.13 0 0 0 1.27 0 5.81 5.81 0 0 0 .8 2.53z" />
        <path d="m143.63 44.31c0-1.13-.06-1.43-.75-1.49h-.49a.26.26 0 0 1 0-.39c.83-.07 1.87-.12 3.3-.12a5.86 5.86 0 0 1 2.57.43 2 2 0 0 1 1.17 1.92 2.21 2.21 0 0 1 -1.57 2.09c0 .16.13.2.29.23a2.54 2.54 0 0 1 2.06 2.56c0 1.78-1.32 3-4.08 3-.44 0-1.19 0-1.84 0s-1.19 0-1.76 0c-.09 0-.12-.29 0-.38l.28-.05c.81-.13.84-.3.84-1.91zm1.26 2c0 .43 0 .46.79.43 1.56-.07 2.41-.52 2.41-2a2 2 0 0 0 -2.29-2.07 1.93 1.93 0 0 0 -.7.09c-.15 0-.21.12-.21.4zm0 3.55a3 3 0 0 0 .32 1.67 1.49 1.49 0 0 0 1.21.43c1.36 0 2.4-.61 2.4-2.15 0-1.21-.61-2.61-3-2.61-.82 0-.88.09-.88.39z" />
        <path d="m153.64 44.62c0-1.6 0-1.83-.93-1.93h-.25c-.09-.06-.06-.33 0-.38h1.81 2.88a18.91 18.91 0 0 0 2.07 0 17.44 17.44 0 0 1 .24 2 .3.3 0 0 1 -.38 0c-.23-.72-.37-1.26-1.16-1.46a7.87 7.87 0 0 0 -1.49-.09h-1.1c-.46 0-.46 0-.46.62v3.06c0 .43 0 .43.51.43h.88a5.88 5.88 0 0 0 1.31-.09c.18-.07.29-.16.36-.54l.13-.63a.29.29 0 0 1 .39 0c0 .36-.06 1-.06 1.54s.06 1.14.06 1.48a.3.3 0 0 1 -.39 0l-.14-.6a.66.66 0 0 0 -.48-.58 5.1 5.1 0 0 0 -1.18-.08h-.88c-.46 0-.51 0-.51.42v2.21c0 .81 0 1.33.29 1.59s.51.35 1.85.35a4.43 4.43 0 0 0 2-.23 3.81 3.81 0 0 0 1.09-1.48.29.29 0 0 1 .39.1 13.31 13.31 0 0 1 -.69 2.13c-1.38 0-2.75 0-4.11 0h-1.39c-.66 0-1.18 0-2.08 0-.09 0-.12-.29 0-.38l.5-.05c.88-.07.95-.3.95-1.91z" />
        <path d="m163.35 44.63c0-1.45 0-1.72-.67-1.79l-.49-.06a.26.26 0 0 1 0-.39c.86-.07 1.92-.12 3.42-.12a5.48 5.48 0 0 1 2.56.46 2.3 2.3 0 0 1 1.27 2.15 2.82 2.82 0 0 1 -1.92 2.55c-.07.1 0 .25.08.37a16.53 16.53 0 0 0 3.08 4.08 1.81 1.81 0 0 0 1.06.42.14.14 0 0 1 0 .22 2.21 2.21 0 0 1 -.67.08c-1.31 0-2.09-.39-3.17-1.93-.4-.57-1-1.63-1.5-2.32a1.12 1.12 0 0 0 -1.09-.49c-.69 0-.72 0-.72.34v1.93c0 1.61 0 1.79.92 1.91l.32.05c.09.08.06.34 0 .38-.69 0-1.21 0-1.83 0s-1.19 0-1.91 0c-.09 0-.12-.27 0-.38l.38-.05c.89-.1.92-.3.92-1.91zm1.26 2.19a1.39 1.39 0 0 0 0 .51 3.18 3.18 0 0 0 1.06.07 2.63 2.63 0 0 0 1.58-.39 2.22 2.22 0 0 0 .76-1.9 2.26 2.26 0 0 0 -2.45-2.36c-.95 0-1 .06-1 .49z" />
        <path d="m181.41 50.87c0 .61.09 1 .37 1.08a.14.14 0 0 1 0 .19 3.8 3.8 0 0 0 -.75.13 11.7 11.7 0 0 1 -2.74.4 6.37 6.37 0 0 1 -4.68-1.7 4.85 4.85 0 0 1 -1.4-3.47 5.23 5.23 0 0 1 1.37-3.63 6.34 6.34 0 0 1 4.86-1.8 9.32 9.32 0 0 1 2 .23 4.11 4.11 0 0 0 .94.14 18.71 18.71 0 0 0 .23 2.22c0 .12-.32.14-.41.05-.43-1.66-1.58-2.18-3.14-2.18-3 0-4.27 2.12-4.27 4.41 0 2.94 1.51 5.26 4.59 5.26.95 0 1.52-.2 1.69-.46a1.79 1.79 0 0 0 .15-.87v-.62c0-1.28 0-1.38-1-1.48l-.68-.08c-.12-.06-.11-.34 0-.38h2.19 1.63a.27.27 0 0 1 0 .38h-.24c-.65.08-.66.51-.66 1.29z" />
        <path d="m192.23 52.67a4.89 4.89 0 0 1 -2.39-.56 7.25 7.25 0 0 1 -.41-2.15c.07-.11.3-.14.36 0a2.79 2.79 0 0 0 2.64 2.28 1.73 1.73 0 0 0 1.91-1.76 2.29 2.29 0 0 0 -1.25-2.15l-1.46-.95a3.27 3.27 0 0 1 -1.63-2.67c0-1.46 1.13-2.64 3.12-2.64a5.81 5.81 0 0 1 1.43.2 2.08 2.08 0 0 0 .53.09 6.65 6.65 0 0 1 .28 1.87c-.06.09-.31.14-.38.05a2 2 0 0 0 -2.09-1.73 1.6 1.6 0 0 0 -1.82 1.7 2.4 2.4 0 0 0 1.3 1.88l1.23.77a3.45 3.45 0 0 1 1.91 2.94c0 1.68-1.27 2.83-3.26 2.83" />
        <path d="m199.27 43.37a6.64 6.64 0 0 1 4.14-1.3 11.74 11.74 0 0 1 2.71.37 1 1 0 0 0 .43.06c0 .35.09 1.32.21 2.25a.3.3 0 0 1 -.41 0c-.23-1-.92-2.23-3.14-2.23s-4.34 1.48-4.34 4.65 2 5 4.55 5a3.29 3.29 0 0 0 3.21-2.17.3.3 0 0 1 .39.06 5.61 5.61 0 0 1 -.73 2.1 3.06 3.06 0 0 0 -.54.15 9.18 9.18 0 0 1 -2.42.35 6.68 6.68 0 0 1 -3.81-1 5 5 0 0 1 -2.18-4.22 5.11 5.11 0 0 1 1.93-4.06" />
        <path d="m212.06 47.48c-.74 0-.77 0-.77.49v2.16c0 1.61.08 1.82.94 1.91l.44.05c.09.06.06.34 0 .38-.83 0-1.35 0-1.94 0s-1.19 0-1.78 0c-.1 0-.13-.29 0-.38l.26-.05c.85-.15.89-.3.89-1.91v-5.51c0-1.61-.11-1.87-.91-1.93h-.4c-.09-.06-.06-.33 0-.38h1.94s1.11 0 1.77 0c.09 0 .12.32 0 .38h-.29c-.89.09-.92.32-.92 1.93v1.76c0 .47 0 .49.77.49h4.38c.73 0 .76 0 .76-.49v-1.76c0-1.61 0-1.84-.93-1.93h-.29c-.09-.06-.06-.33 0-.38h1.85s1.12 0 1.81 0c.09 0 .12.32 0 .38h-.32c-.89.09-.92.32-.92 1.93v5.51c0 1.61 0 1.81.92 1.91l.37.05c.09.06.06.34 0 .38-.74 0-1.26 0-1.86 0s-1.18 0-1.85 0c-.09 0-.12-.29 0-.38l.29-.05c.93-.15.93-.3.93-1.91v-2.13c0-.46 0-.49-.76-.49z" />
        <path d="m221.49 47.48a5.3 5.3 0 0 1 5.45-5.41 5 5 0 0 1 5.32 5.26 5.23 5.23 0 0 1 -5.32 5.34 5.15 5.15 0 0 1 -5.45-5.19m9.24.32c0-2.53-1.12-5.25-4-5.25-1.59 0-3.66 1.08-3.66 4.44 0 2.27 1.1 5.21 4.12 5.21 1.84 0 3.59-1.38 3.59-4.4" />
        <path d="m234.42 47.48a5.3 5.3 0 0 1 5.46-5.41 5 5 0 0 1 5.31 5.26 5.23 5.23 0 0 1 -5.31 5.34 5.16 5.16 0 0 1 -5.46-5.19m9.24.32c0-2.53-1.12-5.25-4-5.25-1.66 0-3.66 1.08-3.66 4.45 0 2.27 1.1 5.21 4.12 5.21 1.84 0 3.58-1.38 3.58-4.4" />
        <path d="m248.53 44.62c0-1.61 0-1.86-1-1.93h-.38c-.09-.06-.06-.33 0-.38h2s1.12 0 1.89 0c.09 0 .12.32 0 .38h-.37c-.91.07-.94.32-.94 1.93v5.38c0 1 .05 1.36.33 1.65s.46.35 1.66.35 1.63-.06 1.87-.2a3.23 3.23 0 0 0 1-1.51c.09-.08.38 0 .38.09a11.76 11.76 0 0 1 -.65 2.14c-.78 0-2.23 0-3.81 0h-1.41c-.64 0-1.13 0-2 0-.09 0-.12-.29 0-.38l.46-.05c.89-.09.92-.3.92-1.91z" />
        <path d="m102.74 64.76a5.78 5.78 0 0 1 -1.62 3.48 3 3 0 0 1 -2.19 1.06c-1 0-1.62-.72-1.53-2a5.76 5.76 0 0 1 1.67-3.43 3 3 0 0 1 2.11-1c1.09 0 1.63.82 1.56 1.93m-2.74-.8a8.74 8.74 0 0 0 -1.53 3.83c0 .53.18 1 .62 1s.72-.14 1.21-.8a9.33 9.33 0 0 0 1.43-3.73c0-.48-.08-1-.61-1-.31 0-.68.14-1.12.74" />
        <path d="m107.18 63.62c-.09.26-.21.75-.39 1.42-.46 1.72-.88 3.19-1.37 4.87a6.07 6.07 0 0 1 -1.21 2.47 2.87 2.87 0 0 1 -1.84.82c-.82 0-1.12-.52-1.12-.75a.92.92 0 0 1 .36-.69.25.25 0 0 1 .35.07 1.86 1.86 0 0 0 1.16.62c.31 0 .63-.1 1-1.28.71-2.42 1.25-5.06 2-7.55h-1.27a.39.39 0 0 1 .11-.57h1.31a9.46 9.46 0 0 1 1.73-3.65 3.11 3.11 0 0 1 2.45-1.27 1.12 1.12 0 0 1 1.18 1c0 .4-.41.88-.66.88s-.2-.14-.21-.25c0-.84-.48-1.12-.86-1.12a1.58 1.58 0 0 0 -1.1.83 11.29 11.29 0 0 0 -1.43 3.57h1.92a.42.42 0 0 1 -.16.57z" />
        <path d="m117.73 66.79c0 1.6 0 1.8 1 1.91h.42a.3.3 0 0 1 0 .38c-.88 0-1.38 0-2 0s-1.2 0-1.87 0a.28.28 0 0 1 0-.38h.32c.88-.12.92-.31.92-1.91v-5.66c0-1.3 0-1.56-.72-1.63l-.55-.07a.25.25 0 0 1 0-.38 23.65 23.65 0 0 1 3-.12 5.57 5.57 0 0 1 2.94.61 2.65 2.65 0 0 1 1.23 2.3 2.59 2.59 0 0 1 -1.54 2.43 5 5 0 0 1 -2.11.46c-.11 0-.11-.27 0-.3a2.39 2.39 0 0 0 2.23-2.58 2.27 2.27 0 0 0 -2.47-2.45c-.81 0-.83.06-.83.55z" />
        <path d="m133 62.82a11 11 0 0 0 -.3-3.17c-.1-.19-.38-.29-.76-.32h-.38a.27.27 0 0 1 0-.37h1.75s1.06 0 1.61 0a.27.27 0 0 1 0 .37h-.37a.9.9 0 0 0 -.76.36 13.41 13.41 0 0 0 -.22 3.13v1.73a5.33 5.33 0 0 1 -1.07 3.66 4 4 0 0 1 -2.93 1.12 4.37 4.37 0 0 1 -2.86-.84c-.81-.68-1.2-1.8-1.2-3.62v-3.61c0-1.6 0-1.86-.91-1.93h-.39a.29.29 0 0 1 0-.37h1.9s1.14 0 1.89 0a.29.29 0 0 1 0 .37h-.37c-.89.07-.92.33-.92 1.93v3.31c0 2.46.77 4.08 3.11 4.08s3.18-1.74 3.18-4.07z" />
        <path d="m137.83 61c0-1.14-.06-1.43-.75-1.49h-.48a.24.24 0 0 1 0-.38c.83-.08 1.87-.12 3.29-.12a5.74 5.74 0 0 1 2.58.43 2 2 0 0 1 1.16 1.91 2.22 2.22 0 0 1 -1.56 2.1c0 .15.12.2.29.23a2.53 2.53 0 0 1 2 2.56c0 1.78-1.32 3-4.07 3-.45 0-1.2 0-1.84 0s-1.19 0-1.76 0a.3.3 0 0 1 0-.38h.27c.82-.14.84-.31.84-1.91zm1.26 2c0 .43 0 .46.8.43 1.56-.06 2.4-.52 2.4-2a2 2 0 0 0 -2.29-2.03 1.63 1.63 0 0 0 -.7.1c-.16 0-.22.12-.22.39zm0 3.56a3 3 0 0 0 .32 1.67 1.49 1.49 0 0 0 1.21.42c1.37 0 2.41-.61 2.41-2.14 0-1.21-.61-2.62-3.05-2.62-.83 0-.89.09-.89.4z" />
        <path d="m147.86 61.27c0-1.61 0-1.85-1-1.93h-.39a.29.29 0 0 1 0-.38h2s1.12 0 1.88 0c.09 0 .12.32 0 .38h-.37c-.9.08-.93.32-.93 1.93v5.33c0 1 0 1.37.32 1.66s.46.35 1.67.35 1.63-.06 1.87-.2a3.2 3.2 0 0 0 1-1.52c.09-.07.38 0 .38.1a11.86 11.86 0 0 1 -.64 2.14c-.78 0-2.24 0-3.81 0h-1.41c-.65 0-1.14 0-2 0a.29.29 0 0 1 0-.38h.47c.88-.09.91-.31.91-1.91z" />
        <path d="m157.1 61.3c0-1.62 0-1.88-.94-2h-.38c-.09-.06-.06-.34 0-.38h1.93s1.13 0 1.9 0c.09 0 .12.32 0 .38h-.38c-.91.08-.94.34-.94 2v5.46c0 1.62 0 1.83.94 1.94h.38c.09.06.06.33 0 .38-.77 0-1.29 0-1.9 0s-1.16 0-1.93 0a.3.3 0 0 1 0-.38h.38c.91-.11.94-.32.94-1.94z" />
        <path d="m163.68 60a6.62 6.62 0 0 1 4.14-1.3 11.81 11.81 0 0 1 2.71.37 1.43 1.43 0 0 0 .43.06c0 .35.09 1.32.21 2.25a.32.32 0 0 1 -.41 0c-.23-1-.92-2.24-3.14-2.24s-4.34 1.49-4.34 4.66 2 5 4.55 5a3.25 3.25 0 0 0 3.2-2.19.33.33 0 0 1 .4.07 5.8 5.8 0 0 1 -.73 2.09 3.08 3.08 0 0 0 -.54.16 9.18 9.18 0 0 1 -2.42.35 6.68 6.68 0 0 1 -3.81-1 4.92 4.92 0 0 1 -2.18-4.21 5.1 5.1 0 0 1 1.93-4.07" />
        <path d="m181 64.14c-.73 0-.76 0-.76.48v2.17c0 1.6.07 1.82.93 1.91h.45c.09.06.06.33 0 .38-.83 0-1.35 0-1.95 0s-1.18 0-1.78 0a.3.3 0 0 1 0-.38h.26c.86-.15.89-.31.89-1.91v-5.52c0-1.61-.1-1.87-.9-1.93h-.4c-.09-.06-.06-.34 0-.38h1.93s1.12 0 1.78 0c.09 0 .12.32 0 .38h-.29c-.89.09-.92.32-.92 1.93v1.73c0 .48 0 .49.76.49h4.39c.73 0 .76 0 .76-.49v-1.73c0-1.61 0-1.84-.93-1.93h-.29c-.1-.06-.07-.34 0-.38h1.85s1.12 0 1.81 0c.09 0 .12.32 0 .38h-.32c-.89.09-.92.32-.92 1.93v5.52c0 1.6 0 1.8.92 1.91h.37c.09.06.06.33 0 .38-.73 0-1.25 0-1.85 0s-1.18 0-1.85 0a.28.28 0 0 1 0-.38h.29c.93-.15.93-.31.93-1.91v-2.17c0-.45 0-.48-.76-.48z" />
        <path d="m192.1 61.27c0-1.59 0-1.82-.94-1.93h-.24c-.09-.06-.06-.34 0-.38h1.81 2.88 2.06a15.18 15.18 0 0 1 .25 2 .3.3 0 0 1 -.38 0c-.23-.72-.37-1.26-1.17-1.46a7 7 0 0 0 -1.48-.09h-1.11c-.46 0-.46 0-.46.61v3.06c0 .43.05.43.51.43h.89a5.77 5.77 0 0 0 1.3-.09c.18-.06.29-.15.37-.53l.12-.63a.32.32 0 0 1 .4 0c0 .37-.06 1-.06 1.55s.06 1.13.06 1.47c-.06.11-.32.11-.4 0l-.14-.6c-.06-.28-.17-.51-.47-.58a5.16 5.16 0 0 0 -1.18-.08h-.89c-.46 0-.51 0-.51.41v2.16c0 .82.05 1.34.3 1.6s.5.35 1.85.35a4.46 4.46 0 0 0 2-.23 3.89 3.89 0 0 0 1.09-1.49.28.28 0 0 1 .38.11 13.31 13.31 0 0 1 -.69 2.13c-1.38 0-2.74 0-4.1 0h-1.38c-.66 0-1.18 0-2.09 0a.3.3 0 0 1 0-.38h.51c.87-.08.95-.31.95-1.91z" />
        <path d="m206.08 65.53c-.11-.34-.17-.38-.63-.38h-2.31c-.39 0-.48 0-.58.36l-.56 1.61a6.07 6.07 0 0 0 -.34 1.27c0 .19.08.28.45.31h.5a.24.24 0 0 1 0 .38c-.4 0-.87 0-1.59 0-.51 0-1.06 0-1.47 0a.3.3 0 0 1 0-.38h.5a1 1 0 0 0 .92-.75c.28-.7.71-1.7 1.35-3.42l1.56-4.13a2.09 2.09 0 0 0 .26-1.09 1.74 1.74 0 0 0 .8-.58.22.22 0 0 1 .23.14c.2.67.44 1.38.67 2.05l2.1 6.16c.45 1.31.65 1.54 1.3 1.62h.42c.1.07.07.32 0 .38-.63 0-1.17 0-1.86 0s-1.31 0-1.76 0a.26.26 0 0 1 0-.38h.45c.32 0 .53-.12.53-.27a2.91 2.91 0 0 0 -.2-.82zm-3-1.36c-.13.35-.11.36.32.36h1.88c.45 0 .48-.06.34-.47l-.83-2.5c-.12-.36-.26-.78-.35-1a7.64 7.64 0 0 0 -.37.9z" />
        <path d="m212.11 61.27c0-1.61 0-1.85-1-1.93h-.38c-.09-.06-.06-.34 0-.38h2s1.12 0 1.89 0c.09 0 .12.32 0 .38h-.37c-.9.08-.93.32-.93 1.93v5.33c0 1 0 1.37.32 1.66s.46.35 1.67.35 1.62-.06 1.87-.2a3.35 3.35 0 0 0 1-1.52c.09-.07.38 0 .38.1a12.73 12.73 0 0 1 -.64 2.14c-.78 0-2.24 0-3.82 0h-1.41c-.64 0-1.13 0-2 0a.3.3 0 0 1 0-.38h.46c.89-.09.92-.31.92-1.91z" />
        <path d="m223.49 66.79c0 1.6 0 1.83.92 1.91h.49c.09.06.06.33 0 .38-.88 0-1.4 0-2 0s-1.15 0-2.13 0a.27.27 0 0 1 0-.38h.55c.87-.08.95-.31.95-1.91v-6.85c0-.48 0-.49-.46-.49h-.84a3.13 3.13 0 0 0 -1.88.38 2.73 2.73 0 0 0 -.69 1.06.3.3 0 0 1 -.4-.11 17.36 17.36 0 0 0 .58-2.28h.29c.09.49.6.47 1.3.47h6.21c.82 0 1 0 1.19-.42h.28a9 9 0 0 0 -.23 2.33c-.06.12-.33.12-.4 0-.05-.38-.15-1-.38-1.12a3.09 3.09 0 0 0 -1.78-.38h-1.09c-.46 0-.44 0-.44.52z" />
        <path d="m232.71 64.14c-.74 0-.77 0-.77.48v2.17c0 1.6.08 1.82.93 1.91h.45c.09.06.06.33 0 .38-.83 0-1.35 0-1.95 0s-1.18 0-1.78 0a.3.3 0 0 1 0-.38h.26c.86-.15.89-.31.89-1.91v-5.52c0-1.61-.1-1.87-.9-1.93h-.4c-.09-.06-.06-.34 0-.38h1.93s1.12 0 1.78 0c.09 0 .12.32 0 .38h-.29c-.89.09-.92.32-.92 1.93v1.73c0 .48 0 .49.77.49h4.37c.74 0 .77 0 .77-.49v-1.73c0-1.61 0-1.84-.93-1.93h-.29c-.1-.06-.07-.34 0-.38h1.85s1.12 0 1.81 0c.09 0 .12.32 0 .38h-.32c-.89.09-.92.32-.92 1.93v5.52c0 1.6 0 1.8.92 1.91h.36c.1.06.07.33 0 .38-.73 0-1.25 0-1.85 0s-1.18 0-1.85 0a.28.28 0 0 1 0-.38h.29c.93-.15.93-.31.93-1.91v-2.17c0-.45 0-.48-.77-.48z" />
      </g>
    </svg>
  )
}
