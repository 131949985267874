import styled from "styled-components"
import * as React from "react"
import { useState } from "react"
import { Flex } from "rebass/styled-components"
import Hamburger from "hamburger-react"
import { menuItems } from "../page-data/menu"
import { Link } from "gatsby"
import { theme } from "../theme"

export const HamburgerMenu = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <MobileHeader>
      <HamburgerStyled isOpen={isOpen}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </HamburgerStyled>

      <MobileHeaderContents isOpen={isOpen}>
        {menuItems.map(menuItem => (
          <Link to={menuItem.url} key={menuItem.url}>
            {menuItem.name}
          </Link>
        ))}
      </MobileHeaderContents>
    </MobileHeader>
  )
}
const MobileHeaderContents = styled(Flex)<{ isOpen: boolean }>`
  flex-direction: column;
  padding: 120px 40px 0;
  align-items: center;
  background-color: ${theme.colors.dark};
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? 0 : "100vw")};
  height: 100vh;
  width: 100vw;
  transition: 0.3s all ease-out;
  z-index: 999;

  a {
    color: white;
    text-decoration: none;
    font-family: Arnhem;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    padding-bottom: 40px;
  }
`
const HamburgerStyled = styled.div<{ isOpen: boolean }>`
  z-index: 99999;
  color: ${({ isOpen }) => (isOpen ? "#54DD8B" : "white")};
`
const MobileHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: none;
  }
`
