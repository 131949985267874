import { routes } from "./routes"

export const menuItems = [
  {
    name: "Home",
    url: routes.home,
  },
  {
    name: "About",
    url: routes.about,
  },
  {
    name: "Geography, Ticks and You",
    url: routes.storymap,
  },
  {
    name: "Lyme Map",
    url: routes.overview,
  },
  {
    name: "Data Explorer",
    url: routes.explorer,
  },
  {
    name: "Resources",
    url: routes.news,
  },
]
